<template>
  <div>
    <v-container fluid class="py-6">
        <div class="d-sm-flex justify-between">
            <div class="d-flex ms-auto">
                <v-btn
                    elevation="0"
                    height="43"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-default btn-outline-default
                      shadow-none
                      py-2
                      px-6
                      me-2
                    "
                    color="transparent"
                    small
                    @click="goToCreationPage()"
                >
                    Create
                </v-btn>
            </div>
        </div>

      <v-row class="my-5">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <v-data-table
                  :headers="headers"
                  :items="users"
                  class="table border-radius-xl"
                  hide-default-footer
              >
                  <template v-slot:item.actions="{ item }">
                      <v-btn
                          @click="editItem(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round">
                              fas fa-edit
                          </v-icon>
                      </v-btn>
                      <v-btn
                          @click="deleteItem(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round">
                              fas fa-trash-alt
                          </v-icon>
                      </v-btn>
                  </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axiosIns from '../../../plugins/axios';

export default {
  name: "UserList",
  components: {},
  data: () => {
      return {
          users: [],
          headers: [
              {
                  text: "Id",
                  align: "start",
                  sortable: false,
                  value: "id",
                  class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              },
              {
                  text: "Name",
                  value: "name",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Email",
                  value: "email",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Actions",
                  value: "actions",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
          ]
      };
  },
  computed: {
  },
  beforeMount () {
      this.loadUsers();
  },
  methods: {
      async loadUsers() {
          const response = await axiosIns.get('/api/users');
          if (response && response.status === 200) {
              this.users = response.data.users.data;
          }
      },
      goToCreationPage() {
          this.$router.push('users/create')
      },
      editItem(item) {
          this.$router.push('users/'+item.id);
      },
      async deleteItem(item) {
          if (window.confirm("Do you really want to delete this user?")) {
              axiosIns.delete('/api/users/'+item.id)
                  .then((response) => {
                      console.log(response);
                      this.$router.go(this.$router.currentRoute);
                  })
                  .catch((error) => {
                      this.$store.commit('app/SNACKBAR', {
                          show: true,
                          body: error.response.data.message,
                          type: 'error',
                      });
                  });
          }
      }
  }
};
</script>
